<template>
  <div id="user-view">
    <v-row>
      <!-- <v-col
        cols="12"
        md="5"
        lg="4"
      >
        <user-bio-panel
          :user-data="userData"
        ></user-bio-panel>
      </v-col> -->

      <v-col
        cols="12"
        md="12"
        lg="12"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          fixed-tabs
          class="user-tabs"
        >
          <v-tab
            v-for="(tab,index) in tabs"
            :key="index"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <user-tab-overview></user-tab-overview>
          </v-tab-item>

          <v-tab-item>
            <user-tab-security></user-tab-security>
          </v-tab-item>

          <!-- <v-tab-item>
            <user-tab-notifications></user-tab-notifications>
          </v-tab-item>
          <v-tab-item>
            <user-tab-setting></user-tab-setting>
          </v-tab-item> -->
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'

// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiLockOutline,
  mdiCogOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiLinkVariant,
} from '@mdi/js'
// import UserBioPanel from './user-bio-panel/UserBioPanel.vue'
import userStoreModule from '../userStoreModule'
import UserTabOverview from './user-tab-overview/UserTabOverview.vue'
import UserTabSecurity from './user-tab-security/UserTabSecurity.vue'
// import UserTabNotifications from './user-tab-notifications/UserTabNotifications.vue'
// import UserTabSetting from './user-tab-setting/UserTabSetting.vue'

export default {
  components: {
    // UserBioPanel,
    UserTabOverview,
    UserTabSecurity,
    // UserTabNotifications,
    // UserTabSetting,
  },
  setup(props, context) {
    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const hashRoute = context.root.$route.hash

    const userData = ref({})
    const userTab = ref(hashRoute === '#notifications' ? 2 : null)

    const tabs = [
      { icon: mdiAccountOutline, title: 'Overview' },
      { icon: mdiLockOutline, title: 'Security' },
      // { icon: mdiLockOutline, title: 'Notification' },
      // { icon: mdiCogOutline, title: 'Setting' },
    ]

    // ui

    return {
      tabs,
      userTab,
      userData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
